.single__detail-area {
    padding: 40px 0;
}
.single__detail-area-title {
    position: relative;
}
.single__detail-area-title h3 {
    text-align: start;
}
.single__detail-area-title p {
    margin-bottom: 0;
    text-align: start;
}
.single__detail-area-price {
    position: relative;
    text-align: end;
}
.single__detail-area-price .list-inline {
    position: relative;
    margin: 0;
}

.single__detail-price {
    text-align: right;
}

@media screen and (min-width: 320px) and (max-width: 575px) {
    .single__detail-price {
    text-align: left;
}
.single__detail-price h3 {
    font-size: 18px;
    float: left;
}
.single__detail-price .list-inline {
    text-align: right;
}
.single__detail-desc{text-align: start;}
.single__detail-desc h5 {
    font-size: 16px;
}
.single__detail-title p {
    margin-bottom: 0;
    font-size: 14px;
}
.single__detail-title h3 {
    font-size: 18px;
}
.single__detail-area-price .list-inline {
    text-align: right;
}
.single__detail-area-price h3 {
    font-size: 18px;
    float: left;
}
.single__detail-area-price {
    text-align: start;
    margin-top: 15px;
}
.single__detail-area-title h3 {
    font-size: 18px;
}
.single__detail-features-review {
    margin-bottom: 30px;
}
}
.single__detail-price .list-inline {
    position: relative;
}
.single__detail-desc p {
    line-height: 1.9;
}
.single__detail-desc .show__more {
    max-height: 240px;
    overflow: hidden;
    position: relative;
    transition: max-height 1s;
}
.single__detail-desc .show__more:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 180px;
    display: block;
    background: linear-gradient(rgba(255, 255, 255, 0),  #fff 88%);
    z-index: 1;
    opacity: 1;
    visibility: visible;
    transition: all 1s;
}
.single__detail-desc .show__more.visible {
    max-height: 2000px;
}
.single__detail-desc .show__more.visible:after, .single__detail-desc .show__more.visible .show__more-button {
    opacity: 0;
    visibility: hidden;
}
.single__detail-desc .show__more-button {
    position: absolute;
    bottom: -15px;
    left: 0;
    margin-left: 50%;
    transform: translateX(-50.5%);
    z-index: 2;
    text-align: center;
    display: block;
    opacity: 1;
    min-width: 200px;
    visibility: visible;
    transition: all 0.3s;
    padding: 10px 20px;
    text-transform: capitalize;
}
.single__detail-desc .show__more-button:hover {
    text-decoration: none;
}
.single__detail-features{text-align: start;}
.single__detail-features h5 {
    font-size: 18px;
}

.single__detail-features-title {
    border: 1px solid gray;
}
.single__detail-features-video {
    position: relative;
    border: 1px solid #eaeaea;
    padding: 6px;
}
.single__detail-features-nearby {
    position: relative;
}
.single__detail-features-nearby h6 span {
    background: #c1d0ff;
    width: 25px;
    height: 25px;
    display: inline-block;
    line-height: 25px;
    border-radius: 3px;
    text-align: center;
    margin-right: 7px;
}
.single__detail-features-nearby h6 span i.fa {
    color: var(--primary-color);
}
.single__detail-features-nearby .list-unstyled {
    padding: 0;
    margin: 15px 0 20px 0;
}
.single__detail-features-nearby .list-unstyled li {
    padding: 4px 0;
}
.single__detail-features-nearby .list-unstyled li span {
    float: left;
    color: #495057;
    font-size: 14px;
}
.single__detail-features-nearby .list-unstyled li p {
    margin-bottom: 0;
    text-align: right;
    color: var(--primary-color);
}
.single__detail-features-nearby .list-unstyled li p::before {
    display: inline-block;
    width: 19px;
    height: 19px;
    margin-right: 5px;
    top: -1px;
    position: relative;
    content: "\f041";
    font-family: "FontAwesome";
    font-size: 14px;
    color: var(--primary-color);
    text-align: center;
    line-height: 15px;
    border: 2px solid transparent;
    transition: border-color 0s;
    border-radius: 2px;
}
.single__detail-features-review {
    position: relative;
    display: inline-block;
}
.single__detail-features-review .selected {
    color: var(--primary-color);
}
.single__detail-features-review i.fa {
    font-size: 18px;
}
.form-group{text-align: start;}
.single__detail-features-review .media img {
    position: relative;
    width: 60px;
    -o-object-fit: cover;
    object-fit: cover;
    height: auto;
    max-width: 100%}
.single__detail-features-review .media .media-body span {
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
}
.single__detail-features-review .media .media-body h6 {
    margin: 0;
}
.single__detail-features-review .media .media-body p {
    line-height: 24px;
}
.detail-heading {
    margin: 20px 0 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eaeaea;
}
.property__detail-info h6 {
    margin: 15px 0;
}
.property__detail-info-list {
    position: relative;
}
.property__detail-info-list li {
    margin-bottom: 8px;
    font-size: 16px;
}
.property__detail-info-list li b {
    font-size: 16px;
    margin-right: 3px;
}
.blog__grid {
    margin-top: 30px;
}
.widget__sidebar {
    border: 1px solid #e9ecef;
    margin-top: 30px;
}
.widget__sidebar__header {
    padding: 1rem 1.5rem;
    margin-bottom: 0;
    display: block;
}
.widget__sidebar__header h6 {
    margin-bottom: 0;
}
.widget__sidebar__body {
    padding: 1.5rem;
    border-top: 1px solid #e9ecef;
    transition: all linear 0.25s;
}
.widget__sidebar__body .list-unstyled {
    margin: 0;
    padding: 0;
}
.widget__sidebar__body .list-unstyled li {
    padding: 0 0 15px;
    position: relative;
}
.widget__sidebar__body .list-unstyled li:last-child {
    padding: 0;
}
.widget__sidebar__body .list-unstyled li a {
    font-size: 14px;
    display: block;
    text-decoration: none;
    color: #002247;
    text-transform: capitalize;
    letter-spacing: 0.3px;
    transition: all 0.3s ease;
}
.widget__sidebar__body .list-unstyled li a:hover {
    color: var(--primary-color);
    padding-left: 8px;
}
.widget__sidebar__body .list-unstyled li a span {
    float: right;
    position: relative;
    font-size: 14px;
    background-color: #c1d0ff;
    color: var(--primary-color);
    font-weight: 600;
    line-height: 15px;
    border-radius: 3px;
    height: 22px;
}
.widget__sidebar__body-img {
    position: relative;
    display: inline-block;
    margin-top: 20px;
}
.widget__sidebar__body-img:first-child {
    margin-top: 0;
}
.widget__sidebar__body-img img {
    width: 35%;
    max-width: 100%;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
    float: left;
    margin-right: 5px;
    position: relative;
}
.widget__sidebar__body-heading {
    display: flex;
    overflow: hidden;
}
.widget__sidebar__body-heading h6 {
    line-height: 1.4;
    font-size: 14px;
    margin-bottom: 0;
}
.widget__sidebar__body-heading h6 a {
    color: #002247;
    transition: all 0.3s ease;
}
.widget__sidebar__body-heading h6 a:hover {
    color: var(--primary-color);
    text-decoration: none;
}
.widget__sidebar__footer {
    padding: 1rem;
    border-top: 1px solid #e9ecef;
    transition: all linear 0.25s;
}
.widget__sidebar .btn-search {
    background: #ffffff;
    color: #333333;
    height: 45px;
    box-shadow: none;
    border-radius: 0px;
    border-top: solid 1px #eeeeee;
    border-bottom: solid 1px #eeeeee;
    border-right: solid 1px #eeeeee;
    border-left: solid 1px #eeeeee;
    margin: 0 -1px 0;
}