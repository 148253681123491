.card-item{
    border-radius:10px;
    overflow: hidden;
    text-align: start;
    max-width: 330px;
    max-height: 516px;
    margin: 8px auto;
}
.card-item:hover{
    box-shadow: 0px 0px 30px 0px rgba(19, 19, 28, 0.12);
}
.card-item .my-card-swiper{width: 100%;height: 100% !important;}
.horizontal-card .my-card-swiper{width: 100%;height: 100% !important;}

.card__image {
    border: 1px solid #e9ecef;
    transition: all 0.3s ease;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
}
.card__image-header {
    position: relative;
    overflow: hidden;
    display: block;
    height: 250px;
}
.card__image-header .ribbon,
.card__image-header .unit-view{
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: var(--primary-color);
    color: #fff;
    font-size: 14px;
    position: absolute;
    z-index:10;
    text-align: center;
    margin: auto;
}
.card__image-header .ribbon {
    transform: translate3d(0,  0,  1px) rotate(-45deg);
    padding: 6px;
    left: -30px;
    top: 15px;
    width: 120px;
    height: 30px;
}
.card__image-header .unit-view{
    transform: translate3d(0, 0, 1px) rotate(0deg);
    padding: 3px;
    left: 9px;
    border-radius: 5px;
    bottom: 8px;
    width: 30px;
    height: 25px;
}
.card__image-header .unit-view:hover{
    bottom:5px;
    background-color:white;
    color:var(--primary-color);
}
.card__image-body .unit-category{
    height: 22px;
    width: 80px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.card__image-body .unit-info .location{
    font-size: 16px;
}
.card__image-body .unit-info .location,
.card__image-body .unit-title{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.card__image-body .owner-info .unit-price{
    color:var(--primary-color) !important;
}
.card__image-header .info {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 2px 5px;
    background-color: #002247;
    box-shadow: 1px 2px 1px rgba(94, 93, 99, 0.08), 0 1px 2px rgba(61, 60, 66, 0.12);
    text-transform: capitalize;
    letter-spacing: 1px;
    font-size: 14px;
    color: #fff;
    z-index: 1;
    border-radius:5px;
}
.card__image-header img.img-transition {
    max-width: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    transition: all 0.3s linear 0s;
}
.card__image-header:hover img.img-transition {
    transform: scale(1.05);
}
.horizontal-card .card__image-body{width: 60%;}
.card__image-body {
    padding: 10px;
    position: relative;
    background-color: #fff;
    text-align: start;
}
.card__image-body span {
    font-size: 12px;
}
.card__image-body .badges .veiws span {
    font-size: 16px;
}
.card__image-body h6 a {
    color: #002247;
    text-transform: capitalize;
    line-height: 1.5;
}
.card__image-body .unit-title{
    cursor: pointer;font-weight:600;
}
.h-250 img {
    height: 250px;
    -o-object-fit: cover;
    object-fit: cover;
}
.card-img,
.card-img-top,
.card-img-bottom {width: 100%}
.card-group {
    display: flex;
    flex-direction: column;
}
.card-group>.card {
    margin-bottom: 15px;
}
.card-footer .analysis-wrapper span{font-size: 16px;}
.card-footer .analysis-wrapper .veiws{margin: 0 5px;}


.card-footer .analysis-wrapper .wishlist img{
    cursor: pointer;
    width: 20px;
    max-width: 100%;
}
.card-footer .analysis-wrapper .wishlist:hover img{
    transform: scale(1.1);
}

@media (max-width:576px) {
    .user-wishlist-page .card-item{
        max-width:330px;
    }
    .card-item{
        width: 100% !important;
        max-width: 100%;
    }
    .card__box{
        display: flex;
        align-items:center;
    }
    .card__image-body .body-content{
        height: 75%;
    }
    .card__image-header .ribbon{
        transform: translate3d(0, 0, 1px) rotate(-50deg);
        font-size: 12px;
        padding: 0px;
        left: -24px;
        top: 15px;
        width: 85px;
        height: 20px;
    }
    .card__image-body .unit-category{
        height: 20px;
    }
    .card__image-body .unit-info .location{
        font-size: 14px;
    }
    .card__box .card__image-header,
    .card__box .card__image-body{
        height: 150px;
        flex-basis: 50%;
    }
    .card__image-body{padding: 5px 5px;}
    .card__image-body p ,
    .card__image-body a{font-size: 12px;margin-bottom: 0;}
    .card__image-body span,
    .card__image-body .badges .veiws span 
    {font-size: 12px;margin-bottom: 0;}
    .card__image-body h6{font-size: 14px;}
    .card__image-body .list-row{
        margin: 0;
        flex-direction: column;
        align-items: start;
    }
    .card__image-body p,
    .card__image-body h6{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 145px;
    }
    .card-footer{padding: 3px;}
    .card-footer .list-row{flex-direction: row;}
    .card-footer .contacts{flex-basis:60%;}
    .card-footer .analysis-wrapper{
        justify-content: center !important;
        font-size: 12px;
        flex-basis: 30%;
    }
    .card-footer .analysis-wrapper span{font-size: 12px; margin: 0 1px;}
    .card-footer .analysis-wrapper .veiws{margin: 0 4px;}
}

/*==========================================================================================*/
.horizontal-card .card__image-header{
    width: 350px;
}